import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M10.733 3.635c.5.23.818.695.964 1.41.057.278.064.828.076 6.692.013 6.666.009 6.925-.14 7.46-.156.564-.453.956-.871 1.15q-.804.375-1.648-.17c-.362-.234-1.116-1.052-2.476-2.69l-1.336-1.606-.9-.183c-.992-.202-1.272-.307-1.684-.634-.65-.515-1.152-1.697-1.212-2.855-.063-1.193.403-2.52 1.114-3.178.424-.393.741-.526 1.725-.723.447-.089.846-.17.887-.18.043-.01.627-.68 1.376-1.579C8.17 4.674 8.49 4.315 8.863 4.016c.52-.416.83-.536 1.318-.513.239.01.345.036.552.132M9.66 6.009c-.046.053-.734.877-1.53 1.83-.795.953-1.505 1.785-1.578 1.85-.247.222-.445.288-1.464.493-.66.133-1 .217-1.064.264-.128.095-.3.39-.394.675-.231.704-.187 1.376.136 2.065.1.214.173.32.253.368.068.041.484.142 1.059.257.966.192 1.21.27 1.43.456.068.057.761.868 1.54 1.801.78.934 1.492 1.779 1.582 1.878l.163.18.002-6.109c0-3.36-.01-6.109-.026-6.107a.4.4 0 0 0-.11.099m5.592 2.147c.087.051.677.614 1.312 1.25.636.637 1.172 1.158 1.192 1.158s.564-.529 1.21-1.176c.67-.672 1.234-1.207 1.316-1.25.198-.103.54-.126.764-.05.39.129.628.444.655.87.03.468.005.503-1.356 1.868l-1.18 1.182 1.196 1.2c.658.66 1.224 1.258 1.26 1.327.035.07.07.241.08.384.014.22.004.286-.072.451-.173.375-.492.586-.894.589-.42.003-.484-.045-1.805-1.368l-1.172-1.174-1.195 1.19c-1.326 1.32-1.36 1.347-1.788 1.351-.713.008-1.187-.708-.912-1.38.037-.09.45-.529 1.27-1.352l1.214-1.218-1.198-1.2c-1.337-1.34-1.364-1.376-1.358-1.796a.88.88 0 0 1 .254-.653c.223-.247.423-.325.792-.308.2.008.294.032.415.105",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }