import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M15.025 8.207c.877 0 1.62-.516 1.906-1.225h2.92c.345 0 .649-.278.649-.625 0-.346-.304-.616-.65-.616h-2.919c-.278-.717-1.029-1.241-1.906-1.241-.878 0-1.637.524-1.916 1.24H4.175c-.38 0-.675.27-.675.617s.295.625.675.625h8.943c.278.709 1.03 1.225 1.907 1.225m0-.948c-.557 0-.988-.4-.988-.91 0-.508.43-.901.988-.901.556 0 .987.393.987.902 0 .508-.43.91-.987.91M4.14 11.38a.637.637 0 0 0-.641.625c0 .347.295.616.641.616h3.02c.279.725 1.03 1.241 1.907 1.241.878 0 1.629-.516 1.915-1.24h8.834c.38 0 .683-.27.683-.617s-.304-.624-.683-.624h-8.842c-.278-.71-1.03-1.226-1.907-1.226s-1.628.517-1.906 1.226zm4.927 1.527c-.548 0-.987-.401-.987-.902 0-.509.439-.902.987-.902.557 0 .987.393.987.902 0 .5-.43.902-.987.902m5.957 6.594c.877 0 1.628-.516 1.906-1.233h2.92c.345 0 .649-.27.649-.617 0-.346-.304-.624-.65-.624h-2.919c-.278-.717-1.029-1.225-1.906-1.225-.878 0-1.629.508-1.907 1.225H4.175c-.38 0-.675.277-.675.624s.295.617.675.617h8.934c.287.717 1.038 1.233 1.916 1.233m0-.948c-.557 0-.988-.4-.988-.902 0-.516.43-.901.988-.901.556 0 .987.385.987.901 0 .501-.43.902-.987.902",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }