import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "m12.76 22.3 9.521-9.54c.733-.733.782-1.153.782-2.178v-3.71c0-1.036-.245-1.358-.987-2.1L19.83 2.524c-.732-.732-1.055-.986-2.09-.986h-3.72c-1.026 0-1.436.049-2.168.781L2.3 11.852c-1.397 1.396-1.426 2.841.01 4.267l6.181 6.172c1.436 1.426 2.871 1.406 4.268.01M16.1 9.353a1.316 1.316 0 0 1-1.338-1.338A1.31 1.31 0 0 1 16.1 6.676c.761 0 1.337.586 1.337 1.338 0 .742-.576 1.338-1.337 1.338",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }