import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M4.695 13.094c0 5.015 4.078 9.094 9.094 9.094a9 9 0 0 0 5.461-1.84l6.105 6.117c.211.21.504.328.797.328.68 0 1.125-.516 1.125-1.137 0-.304-.117-.586-.316-.797l-6.082-6.093a9 9 0 0 0 2.004-5.672C22.883 8.078 18.805 4 13.789 4s-9.094 4.078-9.094 9.094m1.524 0c0-4.172 3.398-7.57 7.57-7.57s7.57 3.398 7.57 7.57-3.398 7.57-7.57 7.57-7.57-3.398-7.57-7.57",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }