import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "61",
  height: "61",
  viewBox: "0 0 61 61"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M30.5 18.006h6.247c1.47 0 2.205.367 2.756.551.735.368 1.286.551 1.837 1.103.552.55.919 1.102 1.103 1.837.184.551.367 1.286.551 2.756v12.494c0 1.47-.367 2.205-.551 2.756-.368.735-.551 1.286-1.103 1.837-.55.552-1.102.919-1.837 1.103-.551.184-1.286.367-2.756.551H24.253c-1.47 0-2.205-.367-2.756-.551-.735-.368-1.286-.551-1.837-1.103-.552-.55-.919-1.102-1.103-1.837-.183-.551-.367-1.286-.551-2.756V24.253c0-1.47.367-2.205.551-2.756.368-.735.551-1.286 1.103-1.837.55-.552 1.102-.919 1.837-1.103.551-.183 1.286-.367 2.756-.551zm0-2.756h-6.247c-1.654 0-2.756.367-3.675.735-.918.367-1.837.919-2.756 1.837-.918.919-1.286 1.654-1.837 2.756-.367.919-.551 2.021-.735 3.675v12.494c0 1.654.367 2.756.735 3.675.367.918.919 1.837 1.837 2.756.919.918 1.654 1.286 2.756 1.837.919.367 2.021.551 3.675.735h12.494c1.654 0 2.756-.367 3.675-.735.918-.367 1.837-.919 2.756-1.837.918-.919 1.286-1.654 1.837-2.756.367-.919.551-2.021.735-3.675V24.253c0-1.654-.367-2.756-.735-3.675-.367-.918-.919-1.837-1.837-2.756-.919-.918-1.654-1.286-2.756-1.837-.919-.367-2.021-.551-3.675-.735z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M30.5 22.6c-4.41 0-7.9 3.49-7.9 7.9s3.49 7.9 7.9 7.9 7.9-3.49 7.9-7.9-3.49-7.9-7.9-7.9m0 13.045c-2.756 0-5.145-2.205-5.145-5.145 0-2.756 2.205-5.145 5.145-5.145 2.756 0 5.145 2.205 5.145 5.145 0 2.756-2.389 5.145-5.145 5.145M38.584 24.253a1.837 1.837 0 1 0 0-3.675 1.837 1.837 0 0 0 0 3.675",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }