import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M4.045 19.316h15.478c.918 0 1.475-.468 1.475-1.181 0-.977-.996-1.856-1.836-2.725-.644-.674-.82-2.06-.898-3.183-.069-3.75-1.065-6.329-3.662-7.266-.372-1.28-1.377-2.285-2.823-2.285-1.435 0-2.45 1.006-2.812 2.285-2.598.937-3.594 3.516-3.662 7.266-.078 1.123-.254 2.51-.899 3.183-.85.87-1.836 1.748-1.836 2.725 0 .713.547 1.181 1.475 1.181m7.734 3.916c1.66 0 2.871-1.21 2.998-2.578H8.791c.127 1.367 1.338 2.578 2.988 2.578",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }