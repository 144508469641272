import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12.183 2.018c-.795 0-1.332.547-1.332 1.364v12.73l.085 2.33-2.545-2.879-2.417-2.406a1.37 1.37 0 0 0-.956-.397c-.73 0-1.279.537-1.279 1.289 0 .354.14.687.42.967l7.035 7.047c.258.268.623.418.989.418.365 0 .74-.15.999-.419l7.036-7.046c.28-.28.419-.613.419-.967 0-.752-.548-1.29-1.279-1.29-.386 0-.709.162-.956.398l-2.427 2.406-2.546 2.88.096-2.332V3.381c0-.816-.548-1.363-1.342-1.363",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }