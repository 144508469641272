import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M17.2 22.39q1.29 0 2.256-.388.978-.386 1.794-1.278a.6.6 0 0 0 .097-.097.6.6 0 0 1 .096-.096q.484-.516.71-1.042.236-.526.236-1.02 0-.527-.301-1.01-.29-.485-.913-.903l-3.105-2.138a2.55 2.55 0 0 0-1.418-.461q-.741-.022-1.44.665l-.783.774a.7.7 0 0 1-.376.215.65.65 0 0 1-.409-.108 7 7 0 0 1-.72-.494 43 43 0 0 1-.955-.795 19 19 0 0 1-.956-.913 27 27 0 0 1-.913-.934 16 16 0 0 1-.71-.838q-.3-.409-.526-.784a.66.66 0 0 1-.086-.42.65.65 0 0 1 .215-.365l.763-.784q.687-.698.677-1.45a2.5 2.5 0 0 0-.462-1.407L7.822 3.204Q7.393 2.59 6.91 2.3a1.94 1.94 0 0 0-.988-.3q-1.02-.022-2.063.945a1 1 0 0 1-.107.096q-.042.044-.097.086-.87.806-1.267 1.784Q2 5.878 2 7.157q0 1.503.623 3.18Q3.257 12 4.428 13.73a24 24 0 0 0 2.846 3.384 24.5 24.5 0 0 0 3.374 2.825q1.718 1.182 3.373 1.815 1.665.634 3.18.634m0-1.3q-1.365.021-2.91-.613-1.548-.623-3.105-1.73a25 25 0 0 1-2.965-2.502 24 24 0 0 1-2.546-2.998q-1.117-1.59-1.762-3.158-.633-1.568-.623-2.932.011-.902.322-1.676.322-.784.978-1.375l.075-.075.086-.065q.29-.28.58-.419.3-.14.57-.14.526 0 .859.506l2.084 3.104q.193.3.193.634 0 .333-.333.655l-.87.87q-.483.484-.505 1.064-.01.57.322 1.053.387.558 1.064 1.353.687.795 1.418 1.536.495.483 1.031.978.547.494 1.053.913.505.408.88.666.485.322 1.053.311.57-.01 1.053-.494l.87-.88q.333-.334.666-.334.334 0 .634.194l3.105 2.084q.257.182.375.397.12.214.119.473 0 .558-.559 1.15l-.064.085a3.743 3.743 0 0 1-1.45 1.042 4.7 4.7 0 0 1-1.698.322",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }