import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "m12.453 21.95 9.762-9.798c.687-.687.746-1.035.746-2.09V6.276c0-.965-.189-1.254-.945-2.01l-2.328-2.321c-.747-.757-1.035-.946-2-.946h-3.792c-1.055 0-1.403.06-2.08.737l-9.801 9.768c-1.343 1.345-1.363 2.749 0 4.113l6.339 6.323c1.363 1.364 2.766 1.354 4.1.01m-.826-.925c-.806.806-1.632.816-2.457 0L2.94 14.78c-.816-.806-.806-1.653-.01-2.45l9.752-9.718c.249-.249.498-.378.876-.378h4.19c.347 0 .606.12.865.378l2.736 2.738c.25.25.378.508.378.867v4.192c0 .368-.129.627-.378.876zm4.926-12.407c.697 0 1.214-.538 1.214-1.215s-.517-1.205-1.214-1.205c-.687 0-1.204.528-1.204 1.205s.517 1.215 1.204 1.215",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }