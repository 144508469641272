import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M8 16c4.377 0 8-3.631 8-8 0-4.376-3.631-8-8.008-8C3.624 0 0 3.624 0 8c0 4.369 3.631 8 8 8m0-1.333A6.634 6.634 0 0 1 1.341 8a6.63 6.63 0 0 1 6.651-6.667A6.65 6.65 0 0 1 14.667 8 6.64 6.64 0 0 1 8 14.667m-2.682-3.35a.6.6 0 0 0 .455-.195l2.22-2.228 2.234 2.228a.62.62 0 0 0 .447.196.63.63 0 0 0 .628-.636.6.6 0 0 0-.188-.447L8.886 8.008l2.236-2.243a.58.58 0 0 0 .188-.44.63.63 0 0 0-.628-.627c-.164 0-.298.063-.431.196L7.992 7.13 5.757 4.902a.57.57 0 0 0-.44-.188.623.623 0 0 0-.627.62c0 .172.07.32.188.439l2.228 2.235-2.228 2.235a.6.6 0 0 0-.188.44.63.63 0 0 0 .628.635",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }