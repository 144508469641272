import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M5.375 12.244c0 .215.075.398.236.548l8.594 8.175a.73.73 0 0 0 .526.215.723.723 0 0 0 .742-.742.77.77 0 0 0-.204-.515l-8.079-7.68 8.079-7.682a.82.82 0 0 0 .204-.526.73.73 0 0 0-.742-.741.73.73 0 0 0-.526.215l-8.594 8.185a.72.72 0 0 0-.236.548",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }