import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M3 19.37q0 1.557.795 2.34.795.796 2.363.796h12.58q1.418 0 2.18-.795.773-.794.773-2.342V8.122q0-1.547-.805-2.342-.795-.795-2.353-.795H16.6a3.8 3.8 0 0 0-.58-1.955 4.26 4.26 0 0 0-1.515-1.471Q13.56.999 12.345 1q-1.213 0-2.17.559A4.2 4.2 0 0 0 8.673 3.03a3.94 3.94 0 0 0-.58 1.955H6.158q-1.568 0-2.363.795Q3 6.565 3 8.122zm1.321-.065V8.187q0-.936.473-1.408.484-.472 1.396-.472h12.311q.892 0 1.375.472.494.472.494 1.408v11.118q0 .935-.462 1.407-.462.473-1.203.473H6.19q-.912 0-1.396-.473-.473-.473-.473-1.407m5.092-14.32q.021-.72.387-1.342.375-.624 1.031-1 .656-.386 1.515-.386.87 0 1.514.387.656.375 1.02.999.378.623.398 1.342z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M4.321 19.305V8.187q0-.936.473-1.408.484-.472 1.396-.472h12.311q.892 0 1.375.472.494.472.494 1.408v11.118q0 .935-.462 1.407-.462.473-1.203.473H6.19q-.912 0-1.396-.473-.473-.473-.473-1.407",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }