import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 23"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12.004 18q1.478 0 2.788-.372a11.7 11.7 0 0 0 2.43-.963q1.11-.607 1.99-1.335.885-.727 1.507-1.456.622-.735.952-1.358.33-.63.329-1.016 0-.387-.33-1.009-.329-.63-.95-1.357-.623-.736-1.508-1.464a12 12 0 0 0-1.998-1.328 11.4 11.4 0 0 0-2.43-.97Q13.474 5 12.005 5q-1.456 0-2.767.372a11.6 11.6 0 0 0-2.422.97q-1.112.6-2.005 1.328-.885.727-1.515 1.464-.63.728-.966 1.357Q2 11.113 2 11.5q0 .387.33 1.016.336.622.965 1.358.63.727 1.515 1.456.885.728 1.998 1.335 1.12.6 2.422.963 1.31.372 2.774.372m0-1.198a8.6 8.6 0 0 1-2.32-.311 10.7 10.7 0 0 1-2.086-.82 12.872 12.872 0 0 1-3.132-2.32q-.57-.6-.878-1.092-.307-.494-.307-.759 0-.227.307-.698.308-.47.878-1.07.579-.606 1.369-1.22.79-.615 1.763-1.138a10.2 10.2 0 0 1 2.086-.85 8.2 8.2 0 0 1 2.32-.326 8.1 8.1 0 0 1 2.305.326q1.112.32 2.085.85a12.6 12.6 0 0 1 1.764 1.138q.798.615 1.368 1.22.572.6.879 1.07.315.47.314.698 0 .266-.314.758a7 7 0 0 1-.879 1.093q-.57.59-1.368 1.198-.79.607-1.764 1.123-.973.507-2.085.819a8.5 8.5 0 0 1-2.305.31m0-1.055q.848 0 1.595-.333a4.2 4.2 0 0 0 1.31-.918q.563-.592.878-1.358a4.2 4.2 0 0 0 .322-1.638q0-.888-.322-1.661a4.1 4.1 0 0 0-.878-1.35 4.1 4.1 0 0 0-1.31-.91 3.9 3.9 0 0 0-1.595-.326q-.864 0-1.61.326-.747.325-1.31.91-.564.576-.886 1.35a4.4 4.4 0 0 0-.314 1.661q.007.865.322 1.638.322.766.885 1.358.564.584 1.303.918a3.9 3.9 0 0 0 1.61.333m0-2.836q-.57 0-.974-.417a1.38 1.38 0 0 1-.402-.994q0-.584.402-.994.403-.417.974-.417.562 0 .966.417.402.41.402.994 0 .576-.402.994-.403.417-.966.417",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }