import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M2.165 11.804v.853h8.675q-.275.585-.758 1.179-.483.585-1.07 1.093a7 7 0 0 1-1.165.843q-.578.336-1.06.403-.304.039-.465.21a.59.59 0 0 0-.16.432q0 .249.18.441.189.192.51.134.512-.086 1.166-.421.663-.345 1.326-.882a8 8 0 0 0 1.24-1.266q.569-.72.9-1.562h.057v5.57h.88l.029-5.57h.057a6.5 6.5 0 0 0 .9 1.562 8.1 8.1 0 0 0 2.566 2.148q.653.335 1.174.421.322.058.502-.134a.63.63 0 0 0 .18-.44.59.59 0 0 0-.16-.432q-.153-.172-.465-.211-.483-.067-1.06-.403a7.7 7.7 0 0 1-1.165-.843 9.6 9.6 0 0 1-1.061-1.093 6 6 0 0 1-.767-1.18h8.684v-.853h-6.44a4.5 4.5 0 0 0 1.175-.7q.529-.43.833-.977t.303-1.18q0-1.015-.625-1.648-.615-.642-1.582-.643-.615 0-1.202.288a3.6 3.6 0 0 0-1.061.815 4.6 4.6 0 0 0-.786 1.208V5.17h-.91v3.796a4.3 4.3 0 0 0-.785-1.208 3.5 3.5 0 0 0-1.061-.815 2.7 2.7 0 0 0-1.203-.288q-.957 0-1.581.643-.625.632-.625 1.649 0 .632.303 1.179.312.546.833.978.53.43 1.174.7zm8.95-.087q-.75 0-1.43-.23a5 5 0 0 1-1.222-.623 3.2 3.2 0 0 1-.834-.863q-.303-.489-.303-.978 0-.603.332-.949.33-.354.88-.354.56 0 1.07.297.513.297.91.824.407.518.644 1.199.237.67.237 1.418v.26zm1.761 0h-.275v-.259q0-.747.228-1.418.236-.681.644-1.199.406-.527.918-.824t1.061-.297q.55 0 .88.354.333.345.332.95 0 .488-.303.977-.303.48-.833.863a4.7 4.7 0 0 1-1.213.623q-.681.23-1.439.23M3.794 20h16.412q1.392 0 2.093-.71.7-.7.701-2.08V6.8q0-1.382-.7-2.09Q21.597 4 20.205 4H3.794Q2.402 4 1.7 4.7 1 5.399 1 6.8v10.41q0 1.39.7 2.09.702.7 2.094.7m.019-1.17q-.805 0-1.231-.421-.417-.432-.417-1.266V6.857q0-.824.417-1.256.426-.432 1.23-.431h16.375q.786 0 1.212.431.436.431.436 1.256v10.286q0 .834-.436 1.266-.426.42-1.212.421z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }