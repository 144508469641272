import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "19",
  height: "19",
  fill: "currentColor",
  viewBox: "0 0 19 19"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M13.34 0H5.66A5.667 5.667 0 0 0 0 5.66v7.68C0 16.46 2.54 19 5.66 19h7.68c3.12 0 5.66-2.54 5.66-5.66V5.66C19 2.54 16.46 0 13.34 0m3.749 13.34a3.75 3.75 0 0 1-3.75 3.749H5.66a3.75 3.75 0 0 1-3.749-3.75V5.66a3.75 3.75 0 0 1 3.75-3.749h7.679a3.75 3.75 0 0 1 3.749 3.75z" }, null, -1),
    _createElementVNode("path", { d: "M9.5 4.586A4.92 4.92 0 0 0 4.586 9.5 4.92 4.92 0 0 0 9.5 14.414 4.92 4.92 0 0 0 14.414 9.5 4.92 4.92 0 0 0 9.5 4.586m0 7.917a3.003 3.003 0 1 1 0-6.006 3.003 3.003 0 0 1 0 6.006M14.424 5.8a1.178 1.178 0 1 0 0-2.355 1.178 1.178 0 0 0 0 2.355" }, null, -1)
  ])))
}
export default { render: render }