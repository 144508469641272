import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M6.034 13.075a.6.6 0 0 1-.439-.176.6.6 0 0 1-.164-.427q0-.242.164-.417a.6.6 0 0 1 .439-.176h5.363V4.784q0-.253.175-.417a.57.57 0 0 1 .417-.176.56.56 0 0 1 .428.176q.175.164.175.417v7.688q0 .252-.175.427a.56.56 0 0 1-.428.176zM12 23a10.6 10.6 0 0 1-4.255-.866 11.3 11.3 0 0 1-3.51-2.37 11.4 11.4 0 0 1-2.38-3.509Q1 14.26 1 12q0-2.258.855-4.244a11.3 11.3 0 0 1 2.38-3.51 11 11 0 0 1 3.499-2.38A10.6 10.6 0 0 1 11.989 1q2.26 0 4.255.866 1.995.856 3.51 2.38a11.3 11.3 0 0 1 2.38 3.51Q23 9.74 23 12a10.6 10.6 0 0 1-.866 4.255 11.4 11.4 0 0 1-2.38 3.51 11.1 11.1 0 0 1-3.51 2.369A10.5 10.5 0 0 1 12 23m0-1.415a9.4 9.4 0 0 0 3.729-.745 9.7 9.7 0 0 0 3.06-2.062 9.5 9.5 0 0 0 2.05-3.05A9.4 9.4 0 0 0 21.586 12a9.3 9.3 0 0 0-.745-3.718 9.6 9.6 0 0 0-2.073-3.06 9.45 9.45 0 0 0-3.05-2.061q-1.731-.747-3.728-.746a9.4 9.4 0 0 0-3.729.746 9.45 9.45 0 0 0-3.049 2.061 9.7 9.7 0 0 0-2.05 3.06A9.4 9.4 0 0 0 2.426 12q0 1.984.735 3.729a9.7 9.7 0 0 0 2.061 3.049 9.7 9.7 0 0 0 3.05 2.062q1.731.745 3.728.745",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }