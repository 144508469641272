import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M6.202 18.185a.717.717 0 0 0 .992 0l5-5 5 5a.71.71 0 0 0 .992 0 .707.707 0 0 0 0-.985l-5-5.008 5-5a.7.7 0 0 0 0-.984.703.703 0 0 0-.992 0l-5 5-5-5a.696.696 0 0 0-.992 0 .71.71 0 0 0 0 .984l5 5-5 5.008a.7.7 0 0 0 0 .985",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }