import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "61",
  height: "61",
  viewBox: "0 0 61 61"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "m42.7 19.218-4.583 23.928s-.642 1.659-2.403.863l-10.575-8.396-.05-.025a7275 7275 0 0 0 12.99-12.109c.75-.724.284-1.155-.586-.608l-16.361 10.76-6.313-2.2s-.993-.366-1.089-1.161c-.096-.797 1.122-1.228 1.122-1.228l25.733-10.454s2.115-.962 2.115.63",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }