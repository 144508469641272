import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M10.558 3.614c.476.182.806.604.967 1.232.148.577.173 1.633.173 7.178 0 5.305-.026 6.552-.148 7.082-.144.625-.452 1.066-.899 1.287-.177.088-.282.106-.635.107-.403.001-.439-.007-.775-.174-.5-.25-.97-.742-2.608-2.733-.75-.91-1.395-1.67-1.435-1.687s-.392-.094-.782-.169a11 11 0 0 1-.986-.229c-.36-.12-.75-.385-1.03-.702-.287-.325-.627-1.02-.78-1.596-.106-.4-.12-.527-.12-1.186 0-.661.011-.784.118-1.184.065-.245.22-.658.346-.917.186-.385.286-.53.548-.795.454-.458.753-.592 1.771-.796.92-.184.989-.206 1.105-.37.238-.33 2.574-3.124 2.902-3.47.874-.918 1.516-1.167 2.268-.878m8.957 1.84c.434.121 1.44 1.369 2.013 2.494a9 9 0 0 1 .964 3.718c.107 2.352-.836 4.885-2.418 6.491-.287.292-.368.348-.566.396a.95.95 0 0 1-1.058-.41c-.285-.427-.222-.897.177-1.329a6.6 6.6 0 0 0 1.177-1.7c.484-.974.71-1.958.71-3.09 0-1.835-.603-3.376-1.872-4.783-.227-.252-.317-.39-.368-.568a.98.98 0 0 1 .324-1.042q.39-.325.917-.178M9.329 6.3c-.197.233-.911 1.093-1.587 1.913-1.178 1.429-1.424 1.684-1.716 1.777-.043.014-.509.113-1.036.22-.774.157-.978.213-1.065.296-.135.128-.306.487-.396.832-.088.336-.089 1.034-.001 1.368.092.351.311.776.446.865.061.04.537.16 1.057.267 1.02.207 1.17.255 1.387.44.078.066.786.897 1.572 1.845L9.54 17.99c.065.077.13.129.144.115.014-.015.02-2.77.014-6.126l-.01-6.099zm6.328 1.355c.34.201.916.947 1.272 1.647.45.886.637 1.684.636 2.721 0 1.01-.22 1.927-.665 2.772-.42.798-1.068 1.555-1.415 1.652-.659.185-1.275-.267-1.273-.934.001-.324.081-.499.395-.856.659-.75.991-1.635.991-2.634 0-1.014-.328-1.885-1.009-2.674-.325-.377-.408-.55-.408-.85 0-.268.175-.64.364-.777a1.01 1.01 0 0 1 1.112-.067",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }