import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M10.343 13.276c.17 0 .321-.061.45-.191l5.299-5.42a.59.59 0 0 0 .184-.445.613.613 0 0 0-.622-.63.64.64 0 0 0-.444.185l-4.867 4.977-4.867-4.977a.66.66 0 0 0-.445-.184.613.613 0 0 0-.622.629c0 .177.062.328.178.444l5.305 5.421c.123.13.28.191.45.191",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }