import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "40",
  height: "32",
  viewBox: "0 0 19.6 15"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M3.001 15.24c-1.837 0-2.76-.906-2.76-2.725V2.812C.241 1 1.155.096 2.737.096h14.15c1.838 0 2.76.914 2.76 2.716v9.703c0 1.81-.914 2.724-2.496 2.724zM8.898 8.11c.37.37.712.545 1.064.545.343 0 .686-.167 1.063-.545l6.619-6.539a1.7 1.7 0 0 0-.704-.132H2.948c-.263 0-.492.044-.685.123zm-7.312 4.404c0 .052 0 .105.009.15L6.64 7.68 1.586 2.715v9.8M18.303 2.82v-.079l-5.019 4.94 5.019 4.957V2.82M2.948 13.895H16.94c.255 0 .475-.036.668-.123l-5.247-5.186-.571.562c-.615.607-1.213.862-1.828.862-.624 0-1.213-.255-1.828-.862l-.572-.562-5.255 5.194c.184.08.395.115.641.115",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }