import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M2.165 9.646h19.67V7.51H2.165zM3.785 20h16.421q1.392 0 2.093-.71.7-.7.701-2.08V6.8q0-1.382-.7-2.09Q21.597 4 20.205 4H3.784q-1.382 0-2.083.7Q1 5.399 1 6.8v10.41q0 1.39.7 2.09.702.7 2.084.7m.028-1.17q-.805 0-1.231-.421-.417-.432-.417-1.266V6.857q0-.824.417-1.256.426-.432 1.23-.431h16.375q.786 0 1.212.431.436.431.436 1.256v10.286q0 .834-.436 1.266-.426.42-1.212.421z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }