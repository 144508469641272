import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M10.81 20.62q-.302 0-.566-.137a2.3 2.3 0 0 1-.547-.39l-7.685-7.208q-.314-.292-.42-.547a1.3 1.3 0 0 1-.098-.478q0-.225.098-.479.107-.253.42-.547l7.685-7.168q.303-.292.547-.43.255-.135.557-.136.44 0 .703.303.264.293.264.703v3.906h.341q2.49 0 4.366.752t3.125 2.217q1.26 1.464 1.894 3.613.635 2.149.635 4.942 0 .517-.264.8-.253.285-.586.284-.292 0-.547-.127-.253-.117-.468-.528-.773-1.582-1.905-2.5-1.122-.927-2.666-1.318-1.542-.4-3.584-.4h-.341v3.896q0 .42-.264.693-.264.285-.694.284m-.39-1.563q.147 0 .146-.176v-3.974q0-.303.303-.303h1.084q2.364 0 4.131.498 1.777.498 2.959 1.455a5.96 5.96 0 0 1 1.787 2.315q.03.067.059.107.03.039.068.039.108 0 .107-.127a15 15 0 0 0-.39-3.144 9.8 9.8 0 0 0-1.035-2.686 7.6 7.6 0 0 0-1.758-2.09q-1.065-.879-2.54-1.348-1.473-.468-3.388-.468H10.87q-.303 0-.303-.303V4.77q0-.156-.156-.156-.059 0-.107.029a1 1 0 0 0-.098.068l-7.197 6.826q-.195.176-.195.323 0 .068.039.146.048.068.156.176l7.207 6.758q.117.117.205.117",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }