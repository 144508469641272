import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M4.98 14.61h7.852c1.324 0 2.004-.645 2.004-2.028V7.039c0-1.394-.68-2.039-2.004-2.039H4.98c-1.312 0-1.992.645-1.992 2.04v5.542c0 1.383.68 2.027 1.992 2.027m13.864 0h7.851c1.325 0 2.004-.645 2.004-2.028V7.039C28.7 5.645 28.02 5 26.695 5h-7.851c-1.313 0-1.992.645-1.992 2.04v5.542c0 1.383.68 2.027 1.992 2.027m-13.84-1.301c-.504 0-.727-.223-.727-.75v-5.52c0-.504.223-.738.727-.738h7.805c.504 0 .726.234.726.738v5.52c0 .527-.223.75-.726.75zm13.875 0c-.516 0-.738-.223-.738-.75v-5.52c0-.504.222-.738.738-.738h7.793c.504 0 .726.234.726.738v5.52c0 .527-.222.75-.726.75zM4.98 26.223h7.852c1.324 0 2.004-.645 2.004-2.028v-5.543c0-1.383-.68-2.027-2.004-2.027H4.98c-1.312 0-1.992.645-1.992 2.027v5.543c0 1.383.68 2.028 1.992 2.028m13.864 0h7.851c1.325 0 2.004-.645 2.004-2.028v-5.543c0-1.383-.68-2.027-2.004-2.027h-7.851c-1.313 0-1.992.645-1.992 2.027v5.543c0 1.383.68 2.028 1.992 2.028m-13.84-1.29c-.504 0-.727-.234-.727-.75v-5.519c0-.516.223-.75.727-.75h7.805c.504 0 .726.234.726.75v5.52c0 .515-.223.75-.726.75zm13.875 0c-.516 0-.738-.234-.738-.75v-5.519c0-.516.222-.75.738-.75h7.793c.504 0 .726.234.726.75v5.52c0 .515-.222.75-.726.75z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }