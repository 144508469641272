import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M11.418 17.954h1.15s.348-.038.526-.23c.163-.176.158-.506.158-.506s-.022-1.547.694-1.774c.707-.225 1.614 1.494 2.575 2.155.727.5 1.28.39 1.28.39l2.57-.035s1.345-.083.707-1.142c-.052-.087-.37-.784-1.911-2.215-1.612-1.499-1.396-1.257.546-3.849 1.182-1.579 1.655-2.542 1.507-2.955-.14-.393-1.01-.29-1.01-.29l-2.895.019s-.214-.03-.373.066c-.156.093-.256.31-.256.31s-.458 1.222-1.07 2.261c-1.288 2.193-1.803 2.308-2.014 2.172-.49-.317-.368-1.274-.368-1.954 0-2.125.322-3.01-.626-3.24-.315-.076-.546-.126-1.351-.134-1.033-.011-1.907.003-2.402.246-.33.161-.583.521-.428.542.19.025.624.117.854.43.296.404.286 1.312.286 1.312s.17 2.5-.398 2.812c-.39.213-.925-.222-2.074-2.211-.59-1.02-1.034-2.145-1.034-2.145s-.085-.21-.238-.323c-.186-.137-.445-.18-.445-.18l-2.75.018s-.414.012-.565.191c-.135.16-.01.491-.01.491s2.153 5.047 4.59 7.59c2.236 2.331 4.775 2.178 4.775 2.178",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }