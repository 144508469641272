import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M10.343 6.59a.62.62 0 0 0-.451.192l-5.298 5.421a.59.59 0 0 0-.185.445c0 .355.267.628.622.628a.64.64 0 0 0 .445-.184l4.867-4.977 4.867 4.977c.116.11.273.184.444.184a.613.613 0 0 0 .622-.628.6.6 0 0 0-.177-.445l-5.305-5.42a.6.6 0 0 0-.451-.192",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }