import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M19.656 11.654a.8.8 0 0 0-.263-.589l-5.828-5.81c-.193-.192-.377-.263-.588-.263a.74.74 0 0 0-.765.756c0 .211.07.413.21.554l1.97 2.004 2.953 2.698-2.118-.132H4.108c-.457 0-.773.325-.773.782s.316.783.773.783h11.119l2.127-.132-2.962 2.698-1.97 2.004c-.14.132-.21.343-.21.553 0 .44.334.756.765.756.21 0 .395-.079.57-.246l5.846-5.827a.8.8 0 0 0 .263-.589",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }