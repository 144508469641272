import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "m3.086 15.29 6.148 3.483q.33.188.641.188.32 0 .656-.188l6.14-3.484q.54-.312.813-.703.282-.399.282-1.234V7.164q0-.609-.235-1.008-.225-.398-.718-.687l-5.532-3.14q-.703-.408-1.398-.407-.696 0-1.406.406l-5.524 3.14q-.5.29-.734.688-.227.399-.227 1.008v6.188q0 .835.281 1.234.282.39.813.703m.703-1.032q-.344-.195-.477-.406a1 1 0 0 1-.132-.54V7.415l6.086 3.477v6.484zm12.188 0-5.485 3.117v-6.484l6.086-3.477v5.899q0 .32-.133.539-.132.21-.468.406M9.883 9.797 3.852 6.383 6.266 5l6.03 3.43zm3.672-2.078L7.5 4.297 9 3.445q.89-.507 1.766 0l5.148 2.938z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }