import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12.983 19C5.466 19 1.178 13.745 1 5h3.765c.124 6.418 2.9 9.137 5.098 9.698V5h3.546v5.536c2.17-.239 4.452-2.761 5.221-5.536h3.546a10.8 10.8 0 0 1-1.698 4.002 10.6 10.6 0 0 1-3.126 2.977 10.9 10.9 0 0 1 3.563 2.893A11.15 11.15 0 0 1 23 19h-3.903a6.94 6.94 0 0 0-2.104-3.377 6.73 6.73 0 0 0-3.584-1.626V19z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }