import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M5.9 27h20.2c2.259 0 3.4-1.215 3.4-3.547V8.547C29.5 6.215 28.359 5 26.1 5H5.9C3.654 5 2.5 6.203 2.5 8.547v14.906C2.5 25.797 3.653 27 5.9 27M3.93 8.632c0-1.409.703-2.126 1.994-2.126h20.152c1.268 0 1.994.717 1.994 2.126v13.97l-6.456-6.317c-.553-.485-1.118-.777-1.787-.777s-1.21.255-1.763.765l-5.246 4.945-2.167-2.102c-.485-.462-.992-.717-1.568-.717-.542 0-1.003.243-1.487.705L3.93 22.65zm7.044 7.544c1.487 0 2.709-1.275 2.709-2.867 0-1.567-1.222-2.867-2.71-2.867-1.498 0-2.72 1.3-2.72 2.867 0 1.592 1.222 2.867 2.72 2.867",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }