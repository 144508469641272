import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M2.729 13.089Q1.499 10.922 1.5 8.713q0-1.37.442-2.506a5.8 5.8 0 0 1 1.25-1.965A5.5 5.5 0 0 1 5.08 2.957 6 6 0 0 1 7.43 2.5q1.467 0 2.674.69A5.1 5.1 0 0 1 12 5.006a5.1 5.1 0 0 1 1.897-1.816q1.207-.69 2.674-.69 1.272 0 2.35.457a5.4 5.4 0 0 1 1.887 1.285 5.8 5.8 0 0 1 1.25 1.965q.442 1.136.442 2.506 0 2.209-1.229 4.376-1.218 2.166-3.439 4.227-2.21 2.06-5.174 3.94-.141.096-.324.17A.9.9 0 0 1 12 21.5a.9.9 0 0 1-.323-.074 2 2 0 0 1-.335-.17q-2.954-1.88-5.174-3.94-2.21-2.061-3.439-4.227",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }