import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M9.734 17.695c4.29 0 7.836-3.547 7.836-7.836 0-4.296-3.547-7.836-7.843-7.836-4.29 0-7.829 3.54-7.829 7.836 0 4.29 3.547 7.836 7.836 7.836m0-1.015a6.807 6.807 0 0 1-6.82-6.82A6.807 6.807 0 0 1 9.727 3.03a6.82 6.82 0 0 1 6.836 6.828 6.813 6.813 0 0 1-6.829 6.82m-.046-9.72a.85.85 0 0 0 .859-.85.854.854 0 0 0-.86-.868.859.859 0 1 0 0 1.719m-1.352 7.212h3.203a.434.434 0 1 0 0-.867h-1.125V8.922c0-.32-.164-.531-.469-.531H8.437a.434.434 0 1 0 0 .867h1.024v4.047H8.336a.434.434 0 1 0 0 .867",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }