import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m12.425 4.94-1.21-1.203.69-.683c.349-.335.725-.37 1.033-.062l.232.233c.308.314.294.69-.055 1.039zm-8.06 8.04-1.579.608c-.164.068-.335-.123-.273-.287l.65-1.518 7.464-7.458 1.196 1.203z"
    }, null, -1)
  ])))
}
export default { render: render }