import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "55",
  height: "20",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M35.076 2.275v15.058h-2.602V4.312h-4.645v13.021h-2.602V2.275zm4.357 15.275q-1.72 0-2.645-.672v-2.101q.495.345 1.075.541.603.195 1.42.195 1.225 0 1.85-.715.623-.735.773-2.015h-3.763v-2.036h3.742q-.366-2.492-2.538-2.492-.666 0-1.333.217-.645.216-1.032.455V6.738q.408-.216 1.096-.368.69-.152 1.656-.152 1.205 0 2.108.434a4 4 0 0 1 1.527 1.17q.624.736.924 1.798a7.7 7.7 0 0 1 .323 2.275q0 2.752-1.29 4.203-1.27 1.452-3.893 1.452M50.698 5.005q-1.548 0-2.344-.78-.774-.78-.774-1.95h2.15q0 .563.259.823.278.26.71.26.408 0 .644-.26.237-.282.237-.823h2.15q0 .563-.193 1.062a2.4 2.4 0 0 1-.58.866 2.6 2.6 0 0 1-.947.585q-.56.217-1.312.217m-1.892 1.43v6.76l3.914-6.76H55v10.898h-2.474v-6.738l-3.914 6.738h-2.28V6.435z"
    }, null, -1),
    _createElementVNode("rect", {
      width: "19.88",
      height: "20",
      fill: "#FC3F1D",
      rx: "9.94"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M11.34 16.01h2.077v-12h-3.02c-3.038 0-4.634 1.571-4.634 3.885 0 1.847.875 2.935 2.437 4.057L5.488 16.01h2.249l3.02-4.541-1.047-.708c-1.27-.863-1.888-1.537-1.888-2.987 0-1.278.893-2.141 2.592-2.141h.927z"
    }, null, -1)
  ])))
}
export default { render: render }