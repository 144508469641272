import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M3.65 8.307h8.497c.351 0 .65-.3.65-.657a.657.657 0 0 0-.65-.65H3.65c-.34 0-.65.293-.65.65s.31.657.65.657",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }