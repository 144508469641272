import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M8.828 4.207h14.813C23.594 3.445 23.148 3 22.328 3H10.152c-.832 0-1.277.445-1.324 1.207m-2.332 3h19.477c-.117-.809-.528-1.289-1.407-1.289H7.914c-.89 0-1.3.48-1.418 1.289m.95 21.012h17.577c2.286 0 3.457-1.172 3.457-3.422V12.645c0-2.25-1.171-3.422-3.457-3.422H7.445c-2.297 0-3.457 1.16-3.457 3.422v12.152c0 2.273 1.16 3.422 3.457 3.422m.023-1.442c-1.313 0-2.028-.703-2.028-2.05v-12c0-1.36.715-2.051 2.028-2.051h17.52c1.3 0 2.038.691 2.038 2.05v12c0 1.348-.738 2.051-2.039 2.051z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }