import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M7.406 22.92h8.858c1.26 0 2.168-.87 2.226-2.12l.723-14.58H20.6a.56.56 0 0 0 .566-.566.57.57 0 0 0-.566-.576H16V3.496c0-1.318-.86-2.11-2.266-2.11H9.916c-1.406 0-2.256.792-2.256 2.11v1.582h-4.6a.58.58 0 0 0-.566.576c0 .313.264.567.567.567h1.406l.722 14.58c.06 1.25.948 2.119 2.217 2.119M8.852 3.555c0-.645.439-1.045 1.123-1.045h3.7c.694 0 1.124.4 1.124 1.045v1.523H8.852zM7.504 21.777c-.615 0-1.104-.478-1.133-1.103L5.648 6.22h12.344L17.3 20.674a1.136 1.136 0 0 1-1.143 1.103zm1.416-1.884c.293 0 .488-.196.478-.46l-.322-10.81c-.01-.273-.205-.45-.478-.45-.293 0-.489.186-.489.46l.323 10.8c.01.274.195.46.488.46m2.92 0c.293 0 .488-.186.488-.46v-10.8c0-.274-.195-.46-.488-.46s-.498.186-.498.46v10.8c0 .274.205.46.498.46m2.91.01c.283 0 .479-.186.488-.46l.322-10.8c0-.284-.195-.47-.488-.47-.273 0-.468.177-.478.45l-.322 10.81c-.01.274.185.47.478.47",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }