import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M1.5 8.713q0 2.209 1.229 4.376 1.23 2.166 3.439 4.227 2.22 2.06 5.174 3.94.151.096.335.17A.9.9 0 0 0 12 21.5a.9.9 0 0 0 .334-.074q.183-.075.324-.17 2.964-1.88 5.174-3.94 2.22-2.061 3.439-4.227 1.23-2.167 1.229-4.376 0-1.37-.442-2.506a5.8 5.8 0 0 0-1.25-1.965 5.4 5.4 0 0 0-1.887-1.285 6 6 0 0 0-2.35-.457q-1.467 0-2.674.69A5.1 5.1 0 0 0 12 5.006a5.1 5.1 0 0 0-1.897-1.816Q8.896 2.5 7.429 2.5q-1.272 0-2.35.457a5.5 5.5 0 0 0-1.886 1.285 5.8 5.8 0 0 0-1.251 1.965Q1.5 7.343 1.5 8.713m1.326 0q0-1.466.593-2.57a4.43 4.43 0 0 1 1.638-1.72q1.035-.617 2.361-.617 1.003 0 1.758.361.765.351 1.304.914.55.552.916 1.168.184.255.313.35a.48.48 0 0 0 .291.096.48.48 0 0 0 .291-.096q.13-.095.302-.35.388-.606.938-1.168.55-.563 1.304-.914.754-.36 1.747-.36 1.326 0 2.36.615 1.035.616 1.628 1.72.604 1.105.604 2.571 0 1.487-.722 3.006-.723 1.507-1.984 2.973a24.4 24.4 0 0 1-2.867 2.825 35 35 0 0 1-3.407 2.507q-.15.105-.194.106-.043 0-.183-.106a35 35 0 0 1-3.407-2.507 25 25 0 0 1-2.878-2.825q-1.25-1.466-1.984-2.973-.722-1.52-.722-3.006",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }