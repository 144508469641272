import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12 22c5.474 0 10-4.526 10-10 0-5.484-4.526-10-10.01-10C6.516 2 2 6.516 2 12c0 5.474 4.526 10 10 10m-1.097-5.174c-.259 0-.468-.11-.668-.37l-2.712-3.24c-.11-.16-.189-.329-.189-.508 0-.35.28-.628.618-.628.22 0 .399.08.578.319l2.343 2.881 4.587-7.258c.14-.23.339-.359.548-.359.329 0 .648.24.648.598 0 .16-.09.34-.19.489l-4.925 7.707c-.16.249-.379.369-.638.369",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }