import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "m5.792 20.959 11.077 1.98c1.93.337 3.131-.727 3.131-2.815V7.79c0-1.024-.295-1.71-.886-2.178V3.534c0-1.92-1.27-2.834-3.427-2.446l-10.2 1.82C4.56 3.037 4 3.723 4 4.808V18.86c0 1.194.59 1.89 1.792 2.099M17.154 4.867 9.13 3.435 16.1 2.24c1.133-.199 1.803.279 1.803 1.263v1.532a5.5 5.5 0 0 0-.748-.169M6.068 19.746c-.571-.1-.847-.428-.847-1.045V5.016c0-.646.423-.975 1.132-.855l10.742 1.91c1.123.208 1.684.706 1.684 1.67v12.273c0 1.253-.748 1.87-1.93 1.661zm1.614-11.21 7.936 1.413c.365.06.601-.179.601-.497 0-.298-.167-.458-.443-.507L7.87 7.542c-.365-.06-.62.16-.62.478 0 .278.157.467.432.517m0 3.81 7.936 1.412c.365.07.601-.169.601-.497 0-.298-.167-.457-.443-.507L7.87 11.352c-.365-.06-.62.169-.62.477 0 .278.157.477.432.517",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }