import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M5.253 17.183h9.477q1.226 0 1.836-.61.617-.6.617-1.812V5.22q0-1.209-.617-1.812-.61-.609-1.836-.609H5.253q-1.226 0-1.844.61-.609.6-.609 1.812v9.539q0 1.21.61 1.812.615.61 1.843.61m.016-1.258q-.585 0-.899-.305-.312-.312-.312-.922V5.284q0-.608.312-.914.313-.312.899-.312h9.445q.579 0 .89.312.321.305.321.914v9.414q0 .61-.32.922-.313.305-.89.305zm3.875-2.242q.398 0 .633-.36l3.57-5.625q.07-.108.125-.234a.6.6 0 0 0 .062-.266.5.5 0 0 0-.195-.414.68.68 0 0 0-.437-.156q-.329 0-.547.352l-3.242 5.203-1.54-1.992a.76.76 0 0 0-.28-.25.6.6 0 0 0-.298-.07.57.57 0 0 0-.43.187.6.6 0 0 0-.171.437q0 .243.18.485l1.906 2.343q.148.188.304.274.165.086.36.086",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }