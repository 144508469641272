import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M11.712 22.356q.14 0 .333-.065t.376-.161a107 107 0 0 0 3.803-2.288q1.536-.978 2.46-1.87.923-.89 1.32-1.912.41-1.031.41-2.416V5.479q0-.858-.334-1.235-.322-.387-1.074-.687-.45-.183-1.107-.43l-1.375-.526a89 89 0 0 0-1.471-.548 71 71 0 0 0-1.386-.494q-.645-.236-1.107-.398a4 4 0 0 0-.418-.107 1.77 1.77 0 0 0-.86 0 4 4 0 0 0-.419.107q-.462.15-1.117.387-.645.226-1.375.505a195 195 0 0 0-2.857 1.074q-.645.247-1.107.43-.741.29-1.074.676Q3 4.621 3 5.48v8.165q0 1.385.408 2.406.42 1.01 1.343 1.901.924.892 2.46 1.87 1.536.987 3.792 2.309a2 2 0 0 0 .365.161q.194.064.344.064m0-1.462a.7.7 0 0 1-.29-.064 2.3 2.3 0 0 1-.333-.182 343 343 0 0 1-3.17-1.988q-1.299-.837-2.094-1.579-.784-.741-1.15-1.6-.364-.87-.364-2.095V5.737q0-.408.096-.601.108-.194.408-.312l1.064-.397q.645-.247 1.386-.516.741-.28 1.482-.548t1.375-.505q.645-.236 1.063-.408.162-.065.29-.096.141-.033.237-.033.097 0 .226.033.128.031.29.096.429.172 1.063.42.645.236 1.375.515.741.268 1.482.548.742.268 1.375.505.645.236 1.085.386.3.118.398.312.108.194.107.601v7.649q0 1.224-.365 2.095-.354.858-1.16 1.6-.795.73-2.095 1.568-1.3.828-3.158 1.999-.183.118-.344.182a.7.7 0 0 1-.28.065m-1.171-4.64q.408 0 .666-.376l5.22-8.164q.076-.118.13-.247a.64.64 0 0 0 .064-.269.55.55 0 0 0-.204-.44.7.7 0 0 0-.462-.172q-.344 0-.58.365l-4.866 7.692-2.482-3.062a.76.76 0 0 0-.612-.322.62.62 0 0 0-.451.193.65.65 0 0 0-.183.462q0 .248.194.516l2.879 3.448q.15.194.311.29.172.086.376.086",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }