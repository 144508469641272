import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "48",
  height: "32",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "48",
      height: "32",
      fill: "#F4F4F4",
      rx: "2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#000",
      d: "M19 15.904a.66.66 0 0 0 .656.657h3.592v3.591c0 .358.299.657.656.657a.663.663 0 0 0 .657-.657v-3.591h3.591a.66.66 0 0 0 .657-.657.663.663 0 0 0-.657-.656h-3.591v-3.592a.663.663 0 0 0-.657-.656.663.663 0 0 0-.656.656v3.592h-3.592a.663.663 0 0 0-.656.656"
    }, null, -1)
  ])))
}
export default { render: render }