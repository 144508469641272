import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12.004 18.747q1.018 0 1.954-.177.937-.175 1.785-.483l-.959-1.004q-.651.207-1.346.33a8 8 0 0 1-1.434.122 8.5 8.5 0 0 1-2.32-.314 10.7 10.7 0 0 1-2.086-.828 12.915 12.915 0 0 1-3.132-2.346 7 7 0 0 1-.878-1.104q-.307-.498-.307-.767 0-.199.241-.605.249-.406.703-.928.46-.522 1.105-1.081.651-.56 1.463-1.066l-.907-.95q-.915.59-1.64 1.257-.716.66-1.221 1.303-.498.645-.762 1.189Q2 11.83 2 12.176q0 .392.33 1.028.336.628.965 1.372.63.735 1.515 1.472.885.735 1.998 1.35 1.12.605 2.422.973 1.31.376 2.774.376m0-13.141q-.96 0-1.83.161-.864.161-1.676.445l.966 1.004q.607-.184 1.23-.291.63-.108 1.31-.108 1.2 0 2.312.33 1.113.322 2.086.859.973.528 1.763 1.15.79.62 1.361 1.234.572.606.879 1.081.315.476.314.705 0 .238-.234.644a6 6 0 0 1-.666.905q-.432.499-1.039 1.027a12 12 0 0 1-1.368 1.02l.9.943q.863-.59 1.544-1.242.687-.652 1.163-1.273.477-.629.725-1.157.256-.53.256-.867 0-.39-.33-1.02-.329-.636-.95-1.372a11.8 11.8 0 0 0-1.508-1.48 12 12 0 0 0-1.99-1.341 11.3 11.3 0 0 0-2.43-.981 10 10 0 0 0-2.788-.376m0 10.864q.482 0 .929-.115.454-.123.864-.33l-5.489-5.75q-.205.429-.315.912-.11.476-.11.99.009.873.323 1.655.315.775.878 1.373.563.59 1.31.927a3.9 3.9 0 0 0 1.61.338m3.754-2.615q.168-.383.256-.805.095-.429.095-.874 0-.897-.322-1.679a4.2 4.2 0 0 0-.878-1.364 4.1 4.1 0 0 0-1.31-.92 3.9 3.9 0 0 0-1.595-.33 3.8 3.8 0 0 0-1.61.353zm1.939 4.96a.56.56 0 0 0 .395.185.5.5 0 0 0 .403-.184.58.58 0 0 0 .175-.43.6.6 0 0 0-.175-.406L6.259 5.169A.53.53 0 0 0 5.857 5a.51.51 0 0 0-.403.169.59.59 0 0 0-.16.422q0 .245.16.414z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }