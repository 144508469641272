import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "8",
  height: "17",
  fill: "currentColor",
  viewBox: "0 0 8 17"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M7.313 8.484h-2.26V17H1.629V8.484H0V5.49h1.629V3.554C1.629 2.169 2.269 0 5.083 0L7.62.01v2.906H5.78c-.303 0-.727.155-.727.816v1.761h2.559z" }, null, -1)
  ])))
}
export default { render: render }