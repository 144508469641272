import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M4.595 19.405q.128.127.293.166a.6.6 0 0 0 .342 0 .6.6 0 0 0 .293-.166l6.484-6.475 6.475 6.475q.117.127.283.166a.7.7 0 0 0 .342.01.58.58 0 0 0 .303-.176.6.6 0 0 0 .166-.293.7.7 0 0 0 0-.332.6.6 0 0 0-.166-.303l-6.475-6.475 6.475-6.484a.6.6 0 0 0 .166-.293.58.58 0 0 0 0-.332.55.55 0 0 0-.166-.293.63.63 0 0 0-.303-.176.6.6 0 0 0-.342 0 .52.52 0 0 0-.283.176l-6.475 6.475L5.523 4.6a.58.58 0 0 0-.303-.176.6.6 0 0 0-.332 0 .57.57 0 0 0-.293.176.6.6 0 0 0-.175.293.7.7 0 0 0 0 .332q.048.166.175.293l6.475 6.485-6.475 6.474a.7.7 0 0 0-.175.303.7.7 0 0 0 0 .332q.039.165.175.293",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }