import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  preserveAspectRatio: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m12.521 8.174-.076-.101C16.332 4.999 15.9 4.54 15.9 4.54a8 8 0 0 0-1.22-1.067A7.3 7.3 0 0 0 12.7 2.458C9.752 1.391 6.246 2.23 4.137 4.541c-2.82 3.024-2.87 7.825-.025 10.9 2.998 3.226 8.104 3.429 11.33.457l.128-.128c.05-.05.101-.101.127-.152.914-1.169-.458-4.344-3.176-7.444m2.49 6.86c-.203.203-1.067.228-2.465-.508-1.295-.686-2.743-1.855-4.115-3.278-.229.178 3.582-2.82 3.404-2.667 1.194 1.372 2.21 2.87 2.744 4.115.635 1.449.61 2.16.432 2.338"
    }, null, -1)
  ])))
}
export default { render: render }