import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M17.262 6H6.738C4.674 6 3 7.656 3 9.698v5.204C3 16.944 4.674 18.6 6.738 18.6h10.524c2.064 0 3.738-1.656 3.738-3.698V9.698C21 7.656 19.326 6 17.262 6m-1.629 6.553-4.922 2.322a.197.197 0 0 1-.282-.176v-4.79c0-.145.155-.24.287-.174l4.922 2.467a.195.195 0 0 1-.005.351",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }