import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M16.161 21.278a4.08 4.08 0 0 0 2.339-.702s.349-.247.547-.435c.193-.182.453-.505.453-.505q.129-.17.241-.357a3.73 3.73 0 0 0 .563-2.007q0-1.104-.563-2.018a4.1 4.1 0 0 0-1.499-1.456 4.1 4.1 0 0 0-2.08-.542q-1.145 0-2.092.542a4.26 4.26 0 0 0-1.519 1.456 3.77 3.77 0 0 0-.562 2.018q0 1.104.562 2.007a4.26 4.26 0 0 0 1.52 1.456 4.13 4.13 0 0 0 2.09.543m-1.548-1.456q.7.4 1.548.4.839 0 1.529-.4t1.095-1.075q.414-.667.414-1.475 0-.81-.414-1.475-.405-.667-1.105-1.066a2.9 2.9 0 0 0-1.519-.41q-.848 0-1.548.41a3.1 3.1 0 0 0-1.105 1.066 2.74 2.74 0 0 0-.414 1.475q0 .808.414 1.475.414.675 1.105 1.075",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M6.88 21.278h6.007a5 5 0 0 1-1.056-1.17H6.95q-.858 0-1.302-.42-.435-.418-.434-1.227V3.826q0-.789.434-1.218.444-.438 1.302-.438h10.415q.84 0 1.283.429.444.427.443 1.227v9.268q.346.2.651.467.306.256.563.552V3.798q0-1.39-.73-2.094Q18.854 1 17.424 1H6.88q-1.42 0-2.15.714Q4 2.419 4 3.798V18.47q0 1.398.72 2.102.73.705 2.16.705M8.29 6.024h7.743a.48.48 0 0 0 .345-.133.46.46 0 0 0 .148-.343.42.42 0 0 0-.148-.323.48.48 0 0 0-.345-.133H8.29a.5.5 0 0 0-.365.133.43.43 0 0 0-.138.323q0 .2.138.343a.5.5 0 0 0 .365.133m0 3.33h7.743a.48.48 0 0 0 .345-.133.43.43 0 0 0 .148-.333.43.43 0 0 0-.148-.333.48.48 0 0 0-.345-.133H8.29a.5.5 0 0 0-.365.133.44.44 0 0 0-.138.333q0 .201.138.333a.5.5 0 0 0 .365.134m10.758 10.787c.192-.182.452-.505.452-.505a4 4 0 0 1-1 .94s.349-.247.547-.435",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M19.44 19.713a4.1 4.1 0 0 1-.94.863l2.287 2.167q.265.257.582.257a.6.6 0 0 0 .454-.19.63.63 0 0 0 .177-.457.6.6 0 0 0-.06-.257.9.9 0 0 0-.167-.219l-2.273-2.24z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }