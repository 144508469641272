import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M5.181 22.5q.601 0 1.558-.36a15 15 0 0 0 2.041-.936q1.085-.575 2.061-1.265 1.972.135 3.787-.247a12.8 12.8 0 0 0 3.343-1.182 10.4 10.4 0 0 0 2.653-1.985 8.6 8.6 0 0 0 1.755-2.633A7.9 7.9 0 0 0 23 10.756a7.86 7.86 0 0 0-.848-3.6 9.2 9.2 0 0 0-2.347-2.941q-1.509-1.275-3.51-1.995-1.993-.72-4.29-.72-2.298 0-4.3.72-1.99.72-3.5 1.995a9.3 9.3 0 0 0-2.357 2.941 7.86 7.86 0 0 0-.848 3.6q0 1.665.641 3.167a9 9 0 0 0 1.814 2.705 9.6 9.6 0 0 0 2.722 1.964q-.227.443-.532.915a16 16 0 0 1-.602.895q-.296.421-.513.7-.246.329-.256.647 0 .33.227.535.236.216.68.216m.71-1.306q-.06.02-.079-.02-.01-.032.03-.083.384-.493.887-1.183.513-.678.79-1.244.157-.32.088-.576-.06-.256-.414-.432-1.548-.75-2.653-1.81a7.7 7.7 0 0 1-1.696-2.355 6.5 6.5 0 0 1-.592-2.735q0-1.656.75-3.086a8.2 8.2 0 0 1 2.09-2.53Q6.424 4.04 8.2 3.424t3.806-.617 3.796.617q1.776.617 3.107 1.718a8 8 0 0 1 2.09 2.53q.76 1.428.76 3.085 0 1.635-.76 3.085a8 8 0 0 1-2.09 2.53q-1.332 1.09-3.107 1.717-1.765.617-3.796.617-.246 0-.533-.01-.285-.02-.62-.041a1.1 1.1 0 0 0-.454.072q-.218.082-.434.247a27 27 0 0 1-2.949 1.748q-.69.349-1.124.473",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }