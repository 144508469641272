import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M3 17.689c0 .57.45.955 1.186.955H9.02C9.08 20.484 10.502 22 12.495 22s3.414-1.516 3.475-3.356h4.834c.736 0 1.196-.384 1.196-.955 0-.994-1.002-1.86-1.86-2.736-.828-.866-.91-2.628-1.084-4.183-.163-3.366-1.195-5.67-3.791-6.487C14.968 2.974 13.915 2 12.495 2s-2.473.974-2.76 2.283C7.13 5.1 6.107 7.403 5.933 10.77c-.173 1.555-.255 3.317-1.083 4.183C3.99 15.829 3 16.695 3 17.689m1.502-.197v-.118c.123-.394.808-1.092 1.4-1.703.92-.964 1.094-2.716 1.278-4.773.194-3.662 1.37-5.08 3.148-5.542.297-.069.46-.197.49-.472.072-1.014.686-1.762 1.677-1.762s1.605.748 1.676 1.762c.03.275.194.403.49.472 1.779.463 2.954 1.88 3.149 5.542.184 2.057.357 3.809 1.277 4.773.593.61 1.278 1.31 1.4 1.703v.118zm5.744 1.152h4.497c-.081 1.388-.991 2.273-2.248 2.273s-2.167-.886-2.249-2.273",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }