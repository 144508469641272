import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "18",
  height: "18",
  fill: "none",
  viewBox: "0 0 18 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#2F80DF",
      d: "M4.14 15.648h1.695c.156 0 .273.046.39.164l1.204 1.195c.984.992 1.898.984 2.883 0l1.203-1.196a.53.53 0 0 1 .398-.163h1.688c1.398 0 2.047-.641 2.047-2.047v-1.688c0-.164.046-.273.164-.398l1.195-1.203c.992-.985.984-1.899 0-2.883L15.81 6.226a.5.5 0 0 1-.163-.391V4.14c0-1.391-.641-2.047-2.047-2.047h-1.688a.52.52 0 0 1-.398-.156L10.312.74c-.985-.992-1.899-.984-2.883 0L6.226 1.937a.5.5 0 0 1-.391.156H4.14c-1.399 0-2.047.64-2.047 2.047v1.695c0 .156-.04.273-.156.39L.74 7.43c-.992.984-.984 1.898 0 2.883l1.196 1.203a.52.52 0 0 1 .156.398v1.688c0 1.398.648 2.047 2.047 2.047m3.859-2.961c-.266 0-.484-.102-.687-.375L5.35 9.905a.87.87 0 0 1-.188-.508c0-.359.274-.648.633-.648.219 0 .39.078.586.336l1.586 2.047 3.336-5.36c.148-.242.351-.359.562-.359.336 0 .657.234.657.594 0 .18-.102.36-.196.515l-3.672 5.79c-.164.25-.39.375-.656.375"
    }, null, -1)
  ])))
}
export default { render: render }