import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  height: "16",
  fill: "currentColor",
  viewBox: "0 0 28 28"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M14 28c7.732 0 14-6.268 14-14q0-.543-.04-1.076l-.006.024h-7.768l-1.771 8.453h-4.347l1.811-8.453H8.111l.845-4.025h7.768L18.48.733A14 14 0 0 0 14 0C6.268 0 0 6.268 0 14s6.268 14 14 14m8.36-25.23a14.04 14.04 0 0 1 4.691 6.153h-5.98z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }