import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12.828 3.653C14.118 2.008 15.91 2 15.91 2s.267 1.547-1.014 3.037c-1.368 1.591-2.923 1.331-2.923 1.331s-.292-1.251.855-2.715m-.69 3.799c.663 0 1.894-.883 3.496-.883 2.76 0 3.844 1.9 3.844 1.9s-2.122 1.05-2.122 3.6c0 2.876 2.644 3.867 2.644 3.867s-1.848 5.036-4.345 5.036c-1.147 0-2.038-.748-3.246-.748-1.232 0-2.453.776-3.25.776C6.88 21 4 16.223 4 12.382c0-3.778 2.438-5.76 4.724-5.76 1.487 0 2.64.83 3.413.83",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }