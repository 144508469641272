import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M3 7.904c0 .364.299.657.656.657h3.592v3.591c0 .358.299.657.656.657a.663.663 0 0 0 .657-.657V8.561h3.591a.66.66 0 0 0 .657-.657.663.663 0 0 0-.657-.656H8.561V3.656A.663.663 0 0 0 7.904 3a.663.663 0 0 0-.656.656v3.592H3.656A.663.663 0 0 0 3 7.904",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }