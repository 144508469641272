import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M13.067 21q.323 0 .604-.156.282-.145.583-.446l8.194-8.227q.333-.334.438-.625Q23 11.256 23 11t-.114-.546q-.105-.29-.438-.625l-8.194-8.182a2.8 2.8 0 0 0-.593-.49A1.1 1.1 0 0 0 13.078 1a.95.95 0 0 0-.76.346q-.282.334-.282.802v4.46h-.354q-2.644 0-4.654.858a8.54 8.54 0 0 0-3.342 2.53q-1.332 1.673-2.01 4.125-.675 2.454-.676 5.641 0 .592.27.915.282.323.646.323.302 0 .573-.145.28-.135.5-.602.833-1.806 2.03-2.854 1.197-1.06 2.842-1.505 1.645-.457 3.821-.457h.354v4.448q0 .48.282.792a.96.96 0 0 0 .75.323m.417-1.784q-.156 0-.156-.2v-4.538q0-.345-.323-.345h-1.156q-3.769 0-6.143 1.27-2.364 1.26-3.321 3.601a.6.6 0 0 1-.063.123.1.1 0 0 1-.083.045q-.104 0-.104-.145.042-1.907.406-3.59.375-1.683 1.114-3.066a8.5 8.5 0 0 1 1.874-2.385q1.135-1.004 2.707-1.539 1.573-.535 3.613-.535h1.156q.322 0 .322-.346v-4.66q0-.178.167-.178.053 0 .104.033a.6.6 0 0 1 .115.078l7.673 7.793q.198.201.198.368a.4.4 0 0 1-.042.167q-.041.079-.156.2l-7.684 7.715q-.114.135-.218.134",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }