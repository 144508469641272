import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M15.852 27.508c6.433 0 11.753-5.32 11.753-11.754C27.606 9.309 22.286 4 15.84 4 9.406 4 4.098 9.309 4.098 15.754c0 6.434 5.32 11.754 11.754 11.754m0-1.524a10.21 10.21 0 0 1-10.23-10.23c0-5.672 4.558-10.242 10.218-10.242a10.24 10.24 0 0 1 10.254 10.242c0 5.66-4.57 10.23-10.242 10.23m-5.332-10.23c0 .422.316.738.773.738h3.797v3.809c0 .445.316.762.738.762.457 0 .774-.317.774-.762v-3.809h3.808c.445 0 .762-.316.762-.738 0-.457-.317-.774-.762-.774h-3.808v-3.796c0-.457-.317-.774-.774-.774-.422 0-.738.328-.738.774v3.797h-3.797c-.457 0-.773.316-.773.773",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }