import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "13",
  height: "12",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"url(#a)\" fill-rule=\"evenodd\" d=\"M6.98 9.6 8.15 6h4.351A6 6 0 1 1 8.355.293L6.988 4.5H2.994L2.506 6H6.5L5.33 9.6zm1.658-5.1L9.783.978A6 6 0 0 1 12.312 4.5z\" clip-rule=\"evenodd\"></path><defs><linearGradient id=\"a\" x1=\".5\" x2=\"12.501\" y1=\"5.2\" y2=\"5.2\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#FF5C4D\"></stop><stop offset=\".4\" stop-color=\"#EB469F\"></stop><stop offset=\"1\" stop-color=\"#8341EF\"></stop></linearGradient></defs>", 2)
  ])))
}
export default { render: render }