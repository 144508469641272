import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "61",
  height: "61",
  viewBox: "0 0 61 61"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M42.588 18.395a15.9 15.9 0 0 0-11.27-4.67c-8.815 0-15.956 7.117-15.956 15.901 0 2.78.78 5.56 2.12 7.895L15.25 45.75l8.48-2.224c2.344 1.223 4.91 1.89 7.588 1.89 8.815 0 15.957-7.116 15.957-15.9-.112-4.115-1.674-8.118-4.687-11.12m-3.57 16.902c-.335.89-1.897 1.78-2.678 1.89a6.3 6.3 0 0 1-2.455-.11c-.558-.223-1.34-.445-2.232-.89-4.017-1.668-6.583-5.671-6.807-6.005-.223-.222-1.673-2.112-1.673-4.114s1.004-2.891 1.339-3.336.78-.445 1.115-.445h.782c.223 0 .558-.111.892.667.335.779 1.116 2.78 1.228 2.892a.7.7 0 0 1 0 .667c-.112.222-.224.445-.447.667s-.446.556-.558.667c-.223.223-.446.445-.223.778.223.445 1.005 1.669 2.232 2.78 1.562 1.335 2.79 1.78 3.236 2.002s.67.111.893-.111c.223-.223 1.004-1.112 1.227-1.557s.558-.334.893-.222c.335.11 2.343 1.112 2.678 1.334.446.222.67.334.78.445.112.333.112 1.112-.222 2.001",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }