import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M8.086 16.258q.5 0 .789-.438l7.383-11.625q.102-.164.148-.297a.9.9 0 0 0 .047-.28.7.7 0 0 0-.195-.516.7.7 0 0 0-.516-.196.7.7 0 0 0-.383.094.9.9 0 0 0-.289.305l-7.015 11.18-3.64-4.766q-.282-.383-.673-.383a.73.73 0 0 0-.539.21.7.7 0 0 0-.21.524q0 .14.054.29.054.14.172.28l4.054 5.165q.353.453.813.453",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }