import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M13.686 9.934a.62.62 0 0 0-.192-.452l-5.42-5.297A.59.59 0 0 0 7.628 4 .613.613 0 0 0 7 4.622c0 .178.075.328.185.444l4.976 4.868L7.185 14.8a.66.66 0 0 0-.185.444c0 .356.273.622.629.622a.6.6 0 0 0 .444-.178l5.421-5.304a.6.6 0 0 0 .191-.451",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }