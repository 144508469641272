import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12.01 12.395c2.503 0 4.512-2.203 4.512-4.942 0-2.675-2.02-4.823-4.512-4.823-2.482 0-4.523 2.17-4.512 4.834.01 2.74 2.009 4.93 4.512 4.93m0-1.257c-1.751 0-3.18-1.6-3.18-3.674-.021-1.987 1.429-3.567 3.18-3.567 1.75 0 3.18 1.558 3.18 3.556 0 2.063-1.418 3.685-3.18 3.685M5.06 22.245h13.868c1.579 0 2.32-.505 2.32-1.59 0-2.803-3.523-6.789-9.249-6.789-5.736 0-9.26 3.986-9.26 6.79 0 1.084.752 1.59 2.32 1.59m-.333-1.268c-.495 0-.656-.118-.656-.461 0-2.03 2.868-5.393 7.928-5.393 5.049 0 7.928 3.362 7.928 5.393 0 .343-.172.462-.655.462z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }